/**
 * Grid Variables - CH
 */
/**
 * Custom SASS Mixins - CH
 */
/**
 * Grid Variables - CH
 */
.image-grid {
  display: flex;
  flex-wrap: wrap;
}

.card__item {
  flex-basis: 33%;
  flex-grow: 1;
  min-width: 300px;
  border: 0.5px solid white;
  position: relative;
  font-size: 2em;
}

.card__item .card__bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 21em;
}

.card__item .card__bg:after {
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
  display: block;
  background: linear-gradient(transparent, transparent, rgba(0, 0, 0, 0.4));
}

.card__item .card__content {
  position: absolute;
  bottom: 0.6em;
  left: 1em;
  color: #fff;
  font-size: 1.2em;
}
